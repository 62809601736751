import React from "react"
import { Link } from "gatsby"
import { Header, Segment } from "semantic-ui-react"

import { StyledContainer } from "../components/core"
import AboutCompany from "../components/about-company"
import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import TeamGrid from "../components/team-grid"

const About = () => (
  <Layout>
    <SEO
      title="About SixEye"
      description="History of the company; meet the team; career opportunities."
    />
    <StyledContainer text>
      <AboutCompany headerAs="h1" />
    </StyledContainer>

    <Segment secondary basic>
      <StyledContainer>
        <Header as="h2" textAlign="center">
          Meet the SixEye team
        </Header>
        <TeamGrid columns={4} />
      </StyledContainer>
    </Segment>

    <Segment basic>
      <StyledContainer text>
        Do you want to work with us? Find out about{" "}
        <Link to="/careers/">career opportunities</Link> at SixEye.
      </StyledContainer>
    </Segment>
  </Layout>
)

export default About
