import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Card } from "semantic-ui-react"

import TeamCard from "../components/team-card"

const TeamCardGrid = ({ columns, items }) => (
  <Card.Group itemsPerRow={columns} centered stackable>
    {items.map(({ node }) => (
      <TeamCard
        key={node.id}
        name={node.name}
        title={node.title}
        image={node.image.publicURL}
        linkedIn={node.linkedIn}
      />
    ))}
  </Card.Group>
)

export default props => {
  const data = useStaticQuery(graphql`
    query {
      team: allTeamJson {
        edges {
          node {
            id
            name
            title
            image {
              publicURL
            }
            linkedIn
          }
        }
      }
    }
  `)
  return <TeamCardGrid {...props} items={data.team.edges} />
}
